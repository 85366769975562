import { useNavigate, useParams } from "react-router-dom";
import { MouseEvent, useState } from "react";
import { FormattedMessage } from "react-intl";

import FilterItem from "../common/FilterItem";
import { ReactComponent as BoltIcon } from "../../assets/bolt.svg";
import { ReactComponent as WeldIcon } from "../../assets/weld.svg";
import { ReactComponent as AnchorIcon } from "../../assets/anchor.svg";
import { ReactComponent as EndplateIcon } from "../../assets/end-plate.svg";
import { ReactComponent as FinplateIcon } from "../../assets/fin-plate.svg";
import { ReactComponent as CleatIcon } from "../../assets/Union.svg";
import { ReactComponent as SpliceIcon } from "../../assets/splice.svg";
import { ConnFeatureFilter, FeatureClauses } from "../../types/CDIFilter";

import { saveLastDesignItemFilter } from "../../functions/filterDesginItems/filterDesignItems";
import { trackHubspotPageView } from "../../utils/hubspotUtils";
import CDISearchDropdown from "../common/CDISearchDropdown";

type FilterType = "feature" | "connector";

interface Props {
  featureFilters: ConnFeatureFilter[];
  connectorFilters: ConnFeatureFilter[];
  setFeatureFilters: (featureFilters: ConnFeatureFilter[]) => void;
  setConnectorFilters: (connectorFilters: ConnFeatureFilter[]) => void;
  setDesignCodeFilter: (value: string) => void;
  resetDesignItems: () => void;
}
interface OptionType {
  value: string;
  label: string;
}

const Filters = ({
  featureFilters,
  connectorFilters,
  setFeatureFilters,
  setConnectorFilters,
  resetDesignItems,
  setDesignCodeFilter,
}: Props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const designCodeInitValue = {
    value: "all",
    label: "All",
  }
  const [designCodeSelectValue, setDesignCodeSelectValue] = useState<OptionType>(designCodeInitValue);

  const logSelectedFilterToGoogleAnalytics = (filter: ConnFeatureFilter) => { 
    const event = `cl_filter_${filter.feature}_selected`;

     window.dataLayer.push({
       "event": event,
     });
  }

  const handleApplyFilters = (filter: ConnFeatureFilter, filterType: "feature" | "connector") => {
    let existingFilters;
    let setFilters;

    logSelectedFilterToGoogleAnalytics(filter);

    if (filterType === "feature") {
      existingFilters = featureFilters;
      setFilters = setFeatureFilters;
    } else if (filterType === "connector") {
      existingFilters = connectorFilters;
      setFilters = setConnectorFilters;
    } else {
      return;
    }

    const existingFilterIndex = existingFilters.findIndex(
      (item) => item.feature === filter.feature,
    );

    if (existingFilterIndex !== -1) {
      const updatedFilters = [...existingFilters];
      updatedFilters[existingFilterIndex].clause = filter.clause;
      setFilters(updatedFilters);
      saveLastDesignItemFilter(updatedFilters, filterType);
    } else {
      setFilters([...existingFilters, filter]);
      saveLastDesignItemFilter([...existingFilters, filter], filterType);
    }
    resetDesignItems();
    const redirectUrl = `/set/${id}${window.location.search}`;
    trackHubspotPageView(redirectUrl);
    navigate(redirectUrl);
  };

  const handleSetOnlyFilter = (
    event: MouseEvent<HTMLDivElement>,
    label: string,
    filterType: FilterType,
  ) => {
    if (filterType === "feature") {
      const newFilters = featureFilters.map((filter) => ({
        feature: filter.feature,
        clause: filter.feature === label ? FeatureClauses.must : FeatureClauses.must_not,
      }));
      setFeatureFilters(newFilters);
      saveLastDesignItemFilter(newFilters, "feature");
    } else if (filterType === "connector") {
      const newFilters = connectorFilters.map((filter) => ({
        feature: filter.feature,
        clause: filter.feature === label ? FeatureClauses.must : FeatureClauses.must_not,
      }));
      setConnectorFilters(newFilters);
      saveLastDesignItemFilter(newFilters, "connector");
    }
  };

  const handleClearFilters = () => {
    const clearedConnectorFilters = connectorFilters.map((filter) => ({
      ...filter,
      clause: 0,
    }));
    setConnectorFilters(clearedConnectorFilters);

    const clearedFeatureFilters = featureFilters.map((filter) => ({
      ...filter,
      clause: 0,
    }));
    setFeatureFilters(clearedFeatureFilters);
    setDesignCodeFilter("all");
    setDesignCodeSelectValue(designCodeInitValue)
  };

  // values come from DynamoDB, don't change, so it works properly
  const selectOptions: OptionType[] = [
    { value: "all", label: "All" },
    { value: "ECEN", label: "EN" },
    { value: "American", label: "AISC" },
    { value: "Canada", label: "CSA" },
    { value: "AUS", label: "AS" },
    { value: "CHN", label: "GB" },
    { value: "India", label: "IS" },
    { value: "HKG", label: "HKG" },
  ];
  const designCodeOnChange = (option: OptionType | null) => {
    if (option) {
      setDesignCodeSelectValue(option);
      setDesignCodeFilter(option.value);
    }
  };

  return (
    <div className="filters">
      <div className="filters-group">
        <h4 className="filters-group-title">
          <FormattedMessage id="DesignCode" defaultMessage="Design code" />
        </h4>
        <CDISearchDropdown
            defaultItem={designCodeSelectValue}
            items={selectOptions}
            onSelect={designCodeOnChange}
            isFlagDropdown={true}
            customStyle={
              {
                marginLeft: '0',
                padding: '4px'
              }
            }
          />
      </div>
      <div className="filters-group">
        <h4 className="filters-group-title">
          <FormattedMessage id="Connectors" defaultMessage="Connectors" />
        </h4>
        <FilterItem
          name="Bolt"
          defaultMessage="Bolt"
          icon={<BoltIcon />}
          label="bolt"
          value={connectorFilters.find((x) => x.feature === "bolt")?.clause!}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "bolt", "connector")}
          onChange={handleApplyFilters}
          filters={connectorFilters}
        />
        <FilterItem
          name="Weld"
          defaultMessage="Weld"
          icon={<WeldIcon />}
          label="weld"
          value={connectorFilters.find((x) => x.feature === "weld")?.clause!}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "weld", "connector")}
          onChange={handleApplyFilters}
          filters={connectorFilters}
        />
      </div>
      <div className="filters-group">
        <h4 className="filters-group-title">
          <FormattedMessage id="Features" defaultMessage="Features" />
        </h4>
        <FilterItem
          name="Anchor"
          defaultMessage="Anchor"
          icon={<AnchorIcon />}
          label="anchoring"
          value={featureFilters.find((x) => x.feature === "anchoring")?.clause!}
          onChange={handleApplyFilters}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "anchoring", "feature")}
          filters={featureFilters}
        />
        <FilterItem
          name="Endplate"
          defaultMessage="End plate"
          icon={<EndplateIcon />}
          label="group_endplate"
          value={featureFilters.find((x) => x.feature === "group_endplate")?.clause!}
          onChange={handleApplyFilters}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "group_endplate", "feature")}
          filters={featureFilters}
        />
        <FilterItem
          name="FinPlate"
          defaultMessage="Fin plate"
          icon={<FinplateIcon />}
          label="group_finplate"
          value={featureFilters.find((x) => x.feature === "group_finplate")?.clause!}
          onChange={handleApplyFilters}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "group_finplate", "feature")}
          filters={featureFilters}
        />
        <FilterItem
          name="Cleat"
          defaultMessage="Cleat"
          icon={<CleatIcon />}
          label="cleat"
          value={featureFilters.find((x) => x.feature === "cleat")?.clause!}
          onChange={handleApplyFilters}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "cleat", "feature")}
          filters={featureFilters}
        />
        <FilterItem
          name="Splice"
          defaultMessage="Splice"
          icon={<SpliceIcon />}
          label="spliceplate"
          value={featureFilters.find((x) => x.feature === "spliceplate")?.clause!}
          onChange={handleApplyFilters}
          handleSetOnlyFilter={(event) => handleSetOnlyFilter(event, "spliceplate", "feature")}
          filters={featureFilters}
        />
      </div>

      <div className="filters-clear" onClick={handleClearFilters}>
        <FormattedMessage id="ClearAllFilters" defaultMessage="Clear all filters" />
      </div>
    </div>
  );
};

export default Filters;
